<template>
    <div>
      <el-table
        ref="refTable"
        :data="tableData"
        v-loading="tableLoading"
        border
        :header-cell-style="HEADER_CELL_STYLE"
        :cell-style="SMALL_CELL_STYLE"
      >
        <el-table-column label="Logged At" min-width="100px">
          <template v-slot="scope">
            <div>{{scope.row.loggedAt ? $moment(scope.row.loggedAt).format('YYYY-MM-DD HH:mm:ss'): "_"}}</div>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="username"
          label="User Name"
          min-width="200">
          <template v-slot="scope">
            <div style="white-space: pre;">{{scope.row.event && scope.row.event.includes('DELIVERY_SIGNED') ? formatUsernameOfDeliverySignedEvent(scope.row) : scope.row.username}}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="signerRole"
          label="Signer Role"
          min-width="100">
        </el-table-column>
        <el-table-column
          prop="signerName"
          label="Signer Name"
          min-width="100">
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="event"
          label="Event"
          min-width="230">
          <template v-slot="scope">
            <div style="display: flex; flex-direction: column;">
              <div style="white-space: pre;">{{ formatEvent(scope.row) }}</div>
              <span v-if="scope.row.userPhotoFileId" @click="() => previewFileMixin(scope.row.userPhotoFileId)" style="cursor: pointer; color: blue; text-decoration: underline; margin-top: -5px;">View Attachment</span>
            </div>
          </template>
        </el-table-column>
         <el-table-column
          label="Via Link"
          min-width="60">
          <template v-slot="scope">
            <div>{{scope.row.viaLink ? "YES" :"NO"}}</div>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="method"
          label="Method"
          min-width="80">
             <template v-slot="scope">
              <div>{{ formatMethod(scope.row) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="device"
          label="Client"
          min-width="80">
             <template v-slot="scope">
            <div>{{scope.row.device == null ? '' : scope.row.device}}</div>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="logData"
          label="Log Data">
          <template v-slot="scope">
            <el-popover
              v-if="scope.row.logData"
              placement="left"
              trigger="click">
                <div style="max-height: 300px; overflow-y: scroll;">
                  <!-- <json-viewer :value="JSON.parse(scope.row.logData)" expanded></json-viewer> -->
                  <div v-if="scope.row.logData">
                    <div v-if="JSON.parse(scope.row.logData).form" style="text-decoration: underline;"><strong>{{ JSON.parse(scope.row.logData).form }}</strong></div>
                    <div v-if="JSON.parse(scope.row.logData).description">{{ JSON.parse(scope.row.logData).description }}&nbsp;</div>
                    <div v-for="(data, index) in JSON.parse(scope.row.logData).data" :key="index">
                      <span v-if="data.orderIndex">{{ data.orderIndex }} - </span><span v-if="data.description">{{ data.description }}&nbsp;</span>
                      <span v-if="data.field">
                        <span></span><strong>{{ data.field }}:&nbsp;</strong>
                        <span v-if="data.oldValue && data.newValue">from <span style="color: blue">{{ parseValue(data.field, data.oldValue) }}</span> to <span style="color: blue">{{ parseValue(data.field, data.newValue) }}</span></span>
                        <span v-else-if="data.oldValue && !data.newValue">from <span style="color: blue">{{ parseValue(data.field, data.oldValue) }}</span> to <span style="color: blue">Empty</span></span>
                        <span v-else-if="!data.oldValue && data.newValue">from <span style="color: blue">Empty</span> to <span style="color: blue">{{ parseValue(data.field, data.newValue) }}</span></span>
                      </span>
                    </div>
                  </div>
                </div>
              <el-button type="text" slot="reference" icon="el-icon-view"></el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          label="Reason"
          min-width="80">
          <template v-slot="scope">
            <div style="white-space: pre;">{{ formatReason(scope.row) }}</div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="tableData.length > 0 "
        background
        @size-change="handlePageSizeChange"
        @current-change="handlePageChange"
        :page-size.sync="pagination.pageSize"
        :page-sizes="[5, 10, 20, 50, 100]"
        :current-page="pagination.pageNumber"
        layout="->,total, sizes, prev,  pager, next"
        :total="pagination.total">
      </el-pagination>
  </div>
</template>
<script>
import 'vue-json-viewer/style.css'
import {
  HEADER_CELL_STYLE,
  CELL_STYLE,
  SMALL_CELL_STYLE,
  EBDN_AUDIT_TRAIL_EVENT,
  OWNER_TYPE,
  ULLAGING_SAMPLING_INSPECTION_OPTION,
  LOCATION_ANCHORAGE_OPTION,
  ANCHORAGE_BERTH_OPTION,
  SULPHUR_CONTENT_LIMIT_TYPE
} from '@/constants.js'
import { getAuditTrailsList } from '@/services/modules/ops'
import { mapState } from 'vuex'
import baseMixin from '@/mixins/base-mixin'
export default {
  name: 'AuditTrailsInformation',
  props: {
    jobId: String
  },
  mixins: [baseMixin],
  data () {
    return {
      HEADER_CELL_STYLE,
      SMALL_CELL_STYLE,
      CELL_STYLE,
      EBDN_AUDIT_TRAIL_EVENT,
      tableData: [],
      tableLoading: false,
      pagination: {
        pageSize: 50,
        pageNumber: 0,
        total: 0
      }
    }
  },
  computed: {
    ...mapState({
      permissions: 'permissions'
    }),
  },
  created () {
    this.getTableList(this.pagination.pageSize)

  },
  methods: {
    parseValue(field, value) {
      if (
          field == 'isBunkerTankerChecked' ||
          field == 'isVesselChecked' ||
          field == 'isAllowLineClearing' ||
          field == 'isVesselUnderAnyQualityTesting' ||
          field == 'isProductBlendedInAdvance' ||
          field == 'beforeDeliveryIntact' ||
          field == 'afterDeliveryIntact' ||
          field == 'msdsAndCoqReceived' ||
          field == 'isVesselHasSufficientTankSpace' ||
          field == 'msdsReceived') {
        return (value == 0 || value == false || value == 'false') ? 'No' : (value == 1 || value == true || value == 'true') ? 'Yes' : 'N.A.'
      } else if (field == 'isOwner') {
        return OWNER_TYPE[value]
      } else if (
          field == 'openingType' ||
          field == 'finishedType' ||
          field == 'closingType' ||
          field == 'finished2Type') {
        return ULLAGING_SAMPLING_INSPECTION_OPTION[value]
      } else if (field == 'etaNextType' || field == 'bargeLeftType' || field == 'bargeArrivedType') {
        return LOCATION_ANCHORAGE_OPTION[value]
      } else if (field == 'pilotOnboardToType' || field == 'vesselOnbardType') {
        return ANCHORAGE_BERTH_OPTION[value]
      } else if (field == 'sulphurContentLimitType') {
        return SULPHUR_CONTENT_LIMIT_TYPE[value]
      }

      return value
    },
    doLayout () {
      this.$nextTick(() => {
        this.$refs.refTable.doLayout()
      })
    },
    getTableList (pageSize, pageNumber) {
      this.tableLoading = true

      getAuditTrailsList(this.jobId, pageSize, pageNumber)
        .then(res => {
          if (res?.code === 1000) {
            this.pagination.total = res.data.totalElements
            this.tableData = res.data.content
          }
          this.tableLoading = false
        })
    },
    handlePageChange (pageNumber) {
      this.getTableList(this.pagination.pageSize, pageNumber - 1)
    },
    handlePageSizeChange (pageSize) {
      this.getTableList(pageSize, 0)
    },
    formatEmailOfSentEmailToGuestEvent (item) {
      const receivingShipEmail = item.receivingShipEmail
      const surveyorEmail = item.surveyorEmail

      if (!receivingShipEmail && !surveyorEmail) return this.EBDN_AUDIT_TRAIL_EVENT.EMAIL_SENT_TO_GUEST
      const output = []

      if (receivingShipEmail) {
        output.push(`${this.EBDN_AUDIT_TRAIL_EVENT.EMAIL_SENT_TO_GUEST}_${receivingShipEmail}`)
      }

      if (surveyorEmail) {
        output.push(`${this.EBDN_AUDIT_TRAIL_EVENT.EMAIL_SENT_TO_GUEST}_${surveyorEmail}`)
      }
      return output.join('\n')
    },
    formatEvent (item) {
      if (!item.event) return ''

      if (item.event === this.EBDN_AUDIT_TRAIL_EVENT.EMAIL_SENT_TO_GUEST) {
        return this.formatEmailOfSentEmailToGuestEvent(item)
      }
      else if (item.event.includes('DELIVERY_SIGNED')) {
        return item.signerCargoOfficerId ? `${item.event}_WITH_CODE` : item.event
      }

      return item.event
    },
    formatUsernameOfDeliverySignedEvent (item) {
      return `${item.username}\n${item.contactedRole ? '(' + item.contactedRole + ')' : ''}`
    },
    formatReason (item) {
      const output = []

      if (item.contactSignReason) {
        output.push(item.contactSignReason + (item.contactSignReasonOther ? ` (${item.contactSignReasonOther})` : ''))
      }

      if (item.noReceivingShipEmailReason) {
        output.push(item.noReceivingShipEmailReason + (item.noReceivingShipEmailReasonOther ? ` (${item.noReceivingShipEmailReasonOther})` : ''))
      }

      if (item.noSurveyorEmailReason) {
        output.push(item.noSurveyorEmailReason + (item.noSurveyorEmailReasonOther ? ` (${item.noSurveyorEmailReasonOther})` : ''))
      }

      return output.join('\n')
    },
    formatMethod (item) {
      if (!item.event.includes('DELIVERY_SIGNED')) return ''
      return (item.contactedRole ? 'CONTACT' : 'CONTACTLESS') + ` (${item.fromMobileApp ? 'OFFLINE' : 'ONLINE'})`
    }
  }
}
</script>
<style lang="scss" scoped>

@media screen and (max-width: 599px) {
  /deep/.el-dialog__headerbtn{
    visibility:hidden;
  }
}

</style>
